<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex flex-row align-center justify-space-between mb-4">
      <div class="top-header mb-0">
        <v-icon class="cursor--pointer" @click="backreport">mdi-reply</v-icon>

        {{ "Lead Summary Details" }}
      </div>
    </div>
    <v-card :loading="loadingData">
      <v-card-title primary-title>
        <div>
          <h3 class="headline mb-0">{{ `${title}:  ${getEmployeeName}` }}</h3>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          class="ml-1"
          :disabled="data.length == 0"
          color="primary"
          small
          @click="setDialogPdf"
          >Generate PDF</v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data"
          :items-per-page="10"
          item-key="uuid"
          class="elevation-1"
        >
          <template v-slot:[`item.fullname`]="{ item }">
            {{ getName(item) }}
          </template>
          <template v-slot:[`item.createAt`]="{ item }">
            {{ prettyDate(item.createAt) }}
          </template>
          <template v-slot:[`item.homePhone`]="{ item }">
            {{ getPhone(item) }}
          </template>

          <template v-slot:[`item.isMale`]="{ item }">
            {{ item.isMale ? $t("labelMale") : $t("labelFemale") }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialogPdf"
      persistent
      :overlay="false"
      max-width="800px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          PDF <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="success"
                large
                icon
                @click="generateReport"
                ><v-icon>mdi-content-save</v-icon></v-btn
              >
            </template>
            <span>Download PDF</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="error" large icon @click="cancelGenerate"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </template>
            <span>Cancel</span>
          </v-tooltip>
        </v-card-title>
        <div
          id="pdf"
          :style="{
            width: '800px !important',
            padding: '10px 10px 10px 5px !important',
            'text-align': 'center',
          }"
        >
          <v-divider></v-divider>
          <h3>{{ title }}: {{ getEmployeeName }}</h3>
          <v-divider></v-divider>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" v-for="ti in headers" :key="ti.text">
                    {{ ti.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in data" :key="item.uuid">
                  <td class="text-left">{{ prettyDate(item.createAt) }}</td>
                  <td class="text-left">{{ getName(item) }}</td>
                  <td class="text-left">
                    {{ item.isMale ? $t("labelMale") : $t("labelFemale") }}
                  </td>
                  <td class="text-left">{{ item.email }}</td>

                  <td class="text-left">{{ getPhone(item) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider></v-divider>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "details-lead-summary",
  data() {
    return {
      title: "",
      dialogPdf: false,
      data: [],
      totalserverData: 0,
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters([
      "getpatientN",
      "getSellerN",
      "getCoordinatorN",
      "getleadHeaders",
      "getviewpatientStatus",
    ]),
    intervarThisYear() {
      const from = moment().startOf("year").utc().toISOString();
      const to = moment().utc().toISOString();

      return {
        date1: from,
        date2: to,
      };
    },

    headers() {
      return [
        {
          text: "Creation Date",
          align: "start",
          sortable: false,
          value: "createAt",
        },
        { text: this.$t("Fullname"), value: "fullname", sortable: false },
        { text: this.$t("labelGender"), value: "isMale", sortable: false },
        { text: "Email", value: "email", align: "start", sortable: false },
        {
          text: this.$t("labelHomePhone"),
          value: "homePhone",
          align: "start",
          sortable: false,
        },
      ];
    },

    getEmployeeName() {
      const item = this.data[0];
      if (item == null || item == undefined) {
        return "-";
      }
      const p = this.$route.params.query;
      const type = p.split("_")[0];
      if (type == "leads") {
        const r = item.relations[0];
        if (r == null || r == undefined) {
          return "-";
        }
        return r.employee.fullname;
      }
      if (type == "patients") {
        const r = item.relations[0];
        if (r == null || r == undefined) {
          return "-";
        }
        return r.employee.fullname;
      }

      return "-";
    },
  },
  methods: {
    backreport() {
      this.$router.push("/reports");
    },
    getData() {
      const p = this.$route.params.query;
      const type = p.split("_")[0];
      const employeeUuid = p.split("_")[1];
      let body;
      this.loadingData = true;
      switch (type) {
        case "patients":
          body = {
            employeeUuid: employeeUuid,
            range: {
              limit: 100,
              offset: 0,
            },
            noCoordinator: false,
          };

          getAPI.post("/patient/filterPatients", body).then((res) => {
            this.data = res.data.result;
            this.totalserverData = res.data.count;
            this.loadingData = false;
          });
          break;
        case "leads":
          body = {
            coordinatorUuid: employeeUuid,
            interval: {
              limit: 100,
              offset: 0,
            },
            noCoordinator: false,
          };

          getAPI.post("/social/filter", body).then((res) => {
            this.data = res.data.result;
            this.totalserverData = res.data.count;
            this.loadingData = false;
          });
          break;
        default:
          //
          break;
      }
    },

    prettyDate(date) {
      const d = moment(date).format("MM/DD/YYYY");
      return d;
    },

    getName(item) {
      if (item.fullname) {
        return item.fullname;
      }
      return item.name + " " + item.last_name;
    },

    getPhone(item) {
      if (item.phone) {
        return item.phone;
      }
      return item.homePhone;
    },

    setDialogPdf() {
      this.dialogPdf = true;
    },
    cancelGenerate() {
      this.dialogPdf = false;
    },

    generateReport() {
      const ti = "Lead-Summary-" + this.title + ".pdf";
      window.html2canvas = html2canvas;
      let doc = new jsPDF({
        orientation: "p",
        unit: "pt",
        format: [1200, 792],
      });
      doc.html(document.querySelector("#pdf"), {
        callback: function (pdf) {
          pdf.save(ti);
        },
      });
    },
  },
  mounted() {
    const p = this.$route.params.query;
    const type = p.split("_")[0];
    if (type == "patients") {
      this.title = "Total-Patients";
    } else {
      this.title = "Total-Leads";
    }
    this.getData();
  },
};
</script>
<style lang=""></style>
<i18n>
{
  "en": {
    "Fullname": "Full name",
    "dialogTitle": "Reason why you open the details of :",
    "notification": "Successfully removed",
    "labelGender": "Gender",
    "labelHomePhone": "Phone",
    "labelEmergencyPhone": "Emergency phone"

  },
  "es": {
    "Fullname": "Nombre completo",
    "dialogTitle": "Razon por la que abre los detalles de :",
    "notification": "Eliminado con éxito",
    "labelGender": "Género",
    "labelHomePhone": "Teléfono",
    "labelEmergencyPhone": "Teléfono de emergencias"
  }
}
</i18n>
