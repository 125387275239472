var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between mb-4"},[_c('div',{staticClass:"top-header mb-0"},[_c('v-icon',{staticClass:"cursor--pointer",on:{"click":_vm.backreport}},[_vm._v("mdi-reply")]),_vm._v(" "+_vm._s("Lead Summary Details")+" ")],1)]),_c('v-card',{attrs:{"loading":_vm.loadingData}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s((_vm.title + ": " + _vm.getEmployeeName)))])]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-1",attrs:{"disabled":_vm.data.length == 0,"color":"primary","small":""},on:{"click":_vm.setDialogPdf}},[_vm._v("Generate PDF")])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":10,"item-key":"uuid"},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getName(item))+" ")]}},{key:"item.createAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.prettyDate(item.createAt))+" ")]}},{key:"item.homePhone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPhone(item))+" ")]}},{key:"item.isMale",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isMale ? _vm.$t("labelMale") : _vm.$t("labelFemale"))+" ")]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"800px","transition":"dialog-transition"},model:{value:(_vm.dialogPdf),callback:function ($$v) {_vm.dialogPdf=$$v},expression:"dialogPdf"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" PDF "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success","large":"","icon":""},on:{"click":_vm.generateReport}},on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v("Download PDF")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","large":"","icon":""},on:{"click":_vm.cancelGenerate}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Cancel")])])],1),_c('div',{style:({
          width: '800px !important',
          padding: '10px 10px 10px 5px !important',
          'text-align': 'center',
        }),attrs:{"id":"pdf"}},[_c('v-divider'),_c('h3',[_vm._v(_vm._s(_vm.title)+": "+_vm._s(_vm.getEmployeeName))]),_c('v-divider'),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.headers),function(ti){return _c('th',{key:ti.text,staticClass:"text-left"},[_vm._v(" "+_vm._s(ti.text)+" ")])}),0)]),_c('tbody',_vm._l((_vm.data),function(item){return _c('tr',{key:item.uuid},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.prettyDate(item.createAt)))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.getName(item)))]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.isMale ? _vm.$t("labelMale") : _vm.$t("labelFemale"))+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.email))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.getPhone(item)))])])}),0)]},proxy:true}])}),_c('v-divider')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }